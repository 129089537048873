import React from 'react'
import { StyledButton, StyledPointerAndTopIconContainer, StyledButtonPointer, StyledFeaturedImage, StyledTopIcon, StyledTitle, StyledTextTop, StyledImageAndTextContainer, StyledImageText, StyledButtonAndPointerContainer, StyledIconPointer } from './styles';

import { StyledModuleContainer, StyledContainer } from '@styles/global-components/_containers';

export default function SingleSignUpCta({ module }) {
    return (
        <StyledModuleContainer
            $sectionOptions={module.sectionOptions}
            moduleSpacing={'md'}
            innerSpacing={module.enableIconPointer ? true : module.enablePointer ? true : ''}
            isOverflowHidden={module.enableIconPointer ? true : module.enablePointer ? true : ''}
            id={module.sectionOptions && module.sectionOptions.sectionId}
            data-name="single-sign-up-cta"
        >
            <StyledContainer>  
                <StyledPointerAndTopIconContainer>
                    {module.topIcon && 
                        <StyledTopIcon 
                            imageFile={module.topIcon.imageFile}
                            alt={module.topIcon.altText}
                        />
                    }

                    {module.enableIconPointer && 
                        <StyledIconPointer color={module.iconPointerColor} />
                    }
                </StyledPointerAndTopIconContainer>
              
                {module.title && 
                    <StyledTitle
                        fontSize={{mobile: 'lg', desktop: 'sm'}}
                    >
                        {module.title}
                    </StyledTitle>
               
                }

                {module.textTop && 
                    <StyledTextTop 
                        bodyFontOptions={{ mobile: 'md', desktop: 'md' }}
                        textAlign={'center'}
                    >
                        {module.textTop}
                    </StyledTextTop>
                }

                <StyledImageAndTextContainer $imageLocation={module.imageDisplay}>
                    {module.featuredImage && 
                        <StyledFeaturedImage 
                            imageFile={module.featuredImage.imageFile}
                            alt={module.featuredImage.altText}
                        />
                    }

                    {module.imageText && 
                        <StyledImageText 
                            bodyFontOptions={{ mobile: 'lg', desktop: 'md' }}
                            $textAlign={module.imageDisplay === 'image-left' ? 'left' : 'right'}
                        >
                            {module.imageText}
                        </StyledImageText>
                    }  
                    
                </StyledImageAndTextContainer>

                <StyledButtonAndPointerContainer>
                    {module.enablePointer && 
                        <StyledButtonPointer 
                            $location={'left'}
                            $altMobileView={module.pointerDisplay === 'double-pointer'}
                            color={module.pointerColor} 
                        />
                    }
                    
                    {module.buttonLink && 
                        <StyledButton 
                            href={module.buttonLink.url} 
                            buttonOptions={module.buttonOptions}
                            fontSize={'md'}
                            radius={'lg'}
                            textDecoration={'none'}
                            fontStyle={'none'}
                            $hasPointer={module.enablePointer}
                            $altMobileView={module.pointerDisplay === 'double-pointer'}
                        >
                            {module.buttonLink.title}
                        </StyledButton>
                    }

                    {module.enablePointer && module.pointerDisplay === 'double-pointer' ?
                        <StyledButtonPointer 
                            $location={'right'}
                            $altMobileView={true}
                            color={module.pointerColor} 
                        />
                    : null}
                </StyledButtonAndPointerContainer>

            </StyledContainer>
        </StyledModuleContainer>
    )
}
