import styled, { css } from 'styled-components';
import { theme } from '@theme';

import WrImage from '@commons/wr-image';
import WrButton from '@commons/wr-button';
import WrPointer from '@commons/wr-pointer';
import ModuleTitle from '@commons/module-title';
import WysiwygContent from '@commons/wysiwyg-content';

const content_spacing = '25px';
const md_content_spacing = '30px';

const text_offset = '20px';


export const StyledPointerAndTopIconContainer = styled.div`
    position: relative;
    max-width: 200px;
    width: 45%;
    margin: 0 auto 20px auto;

    ${theme.mediaQuerys.mdUp} {
        
        ${props => props.$altSize ? css`
            max-width: 240px;
        `:css`
            max-width: 250px; // was 280
            /* margin-bottom: 25px; */
        `}
    }


`

export const StyledTopIcon = styled(WrImage)`
    width: 100%;
`

export const StyledIconPointer = styled(WrPointer)`
    position: absolute;
    top: -90px;
    right: -120px;
    height: 120px;
    transform: rotate(15deg);

    ${theme.mediaQuerys.smUp} {
        height: 130px;
        right: -130px;
        transform: rotate(20deg);
    }

    ${theme.mediaQuerys.mdUp} {
        height: 140px;
        right: -150px;
        transform: rotate(25deg);
    }
`

export const StyledTitle = styled(ModuleTitle)`
    text-align: center;
    margin-bottom: ${content_spacing};

    ${theme.mediaQuerys.mdUp} {
        margin-bottom: ${md_content_spacing};
    }

    ${theme.mediaQuerys.lgUp} {
        padding: 0 20px;
    }
`

export const StyledTextTop = styled(WysiwygContent)`
    margin-bottom: ${content_spacing};
    padding: 0 ${text_offset};

    ${theme.mediaQuerys.smUp} {
        margin-bottom: 35px;
    }

    ${theme.mediaQuerys.mdUp} {
        /* margin-bottom: ${md_content_spacing}; */
        margin-bottom: 45px;
    }

    ${theme.mediaQuerys.lgUp} {
        padding: 0 20px;
    }

    /* p {
        margin: 0;
    } */
`

export const StyledImageAndTextContainer = styled.div`
    margin-bottom: ${content_spacing};

    ${theme.mediaQuerys.smUp} {
        display: flex;
        flex-direction: ${props => props.$imageLocation === 'image-left' ? 'row' : 'row-reverse'};
        align-items: center;
        padding-bottom: 15px;
    }

    ${theme.mediaQuerys.mdUp} {
        margin-bottom: 30px;
        padding-bottom: 25px;
    }
`

export const StyledFeaturedImage = styled(WrImage)`
    margin-bottom: ${content_spacing};
    width: 100%;

    ${theme.mediaQuerys.smUp} {
        width: 45%;
        /* padding-right: 20px; */
    }

    ${theme.mediaQuerys.mdUp} {
        width: 50%;
        margin-bottom: 10px;
        /* padding-right: 40px; */
    }
`

export const StyledImageText = styled(WysiwygContent)`
    /* font-size: 1.6rem;
    line-height: 20px; */
    padding: 0 ${text_offset};

    ${theme.mediaQuerys.smUp} {
        width: 55%;
        padding-left: 20px;
        text-align: ${props => props.$textAlign || ''};
    }

    ${theme.mediaQuerys.mdUp} {
        /* font-size: 2rem;
        line-height: 26px; */
        width: 50%;
        padding-left: 40px;
    }

    /* p {
        margin-top: 0;
        margin-bottom: ${content_spacing};

        ${theme.mediaQuerys.mdUp} {
            margin-bottom: ${md_content_spacing};
        }
    } */

    > :last-child { // last item shouldn't have margin
        margin-bottom: 0 !important;
    }
`

export const StyledButtonAndPointerContainer = styled.div`
    position: relative;
    margin: 0 auto;

    ${theme.mediaQuerys.smUp} {
        width: 90%;
        max-width: 650px;
    }
`

export const StyledButton = styled(WrButton)`
    width: 100%;

    ${props => props.$hasPointer && !props.$altMobileView ? css`
        width: calc(100% - 40px);
        margin-left: auto;

        ${theme.mediaQuerys.smUp} {
            width: 100%;
        }
    `: null}
`

export const StyledButtonPointer = styled(WrPointer)`
    position: absolute;
    /* top: -60px; */
    /* left: -50px; */
    
    /* transform: rotate(-10deg) scaleX(-1); */

    ${props => !props.$altMobileView ? css`
        top: -60px;
        left: -50px;
        height: 100px;
        transform: rotate(-10deg) scaleX(-1);
    `:css`
        top: -100px;
        right: -20px;
        height: 75px;
        transform: rotate(-30deg);
    `}

    ${theme.mediaQuerys.smUp} {
        /* left: -100px; */
        top: -70px;
        height: 110px;

        ${props => props.$location === 'left' ? css`
            left: -100px;  
            right: unset;    
            transform: rotate(-10deg) scaleX(-1);    
        `:css`
            left: unset;
            right: -100px;
            transform: rotate(10deg) scaleX(1);
        `}
    }

    ${theme.mediaQuerys.mdUp} {       
        top: -80px;
        
        ${props => props.$location === 'left' ? css`
            transform: rotate(-05deg) scaleX(-1);         
        `:css`
            left: unset;
            transform: rotate(05deg) scaleX(1);
        `}
    }
`